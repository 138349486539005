<template>
	<div>
		<main-header></main-header>
		<h1 class="page-title">회원가입</h1>
		<router-view @setId="setId" :id="id"></router-view>
		<Footer />
	</div>
</template>

<script>
import MainHeader from '../components/MainHeader.vue';
import Footer from '../components/Footer.vue';

export default {
	data() {
		return {
			id: '',
		};
	},
	components: { MainHeader, Footer },
	methods: {
		setId(id) {
			this.id = id;
		},
	},
};
</script>

<style></style>
